//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import IndentedContent from '../Partials/IndentedContent.vue';
import ContentTeaser from '../Partials/ContentTeaser.vue';
import TextBlock from '../Partials/TextBlock.vue';
export default {
    name: 'ContentList',
    components: { TextBlock, ContentTeaser, IndentedContent },
    props: ['data'],
    created() {
        try {
            const ratios = {
                2: '16-9',
                3: null,
            };
            const c = Math.max(2, Math.min(3, this.data.contentlist.length));
            this.cols = c;
            this.ratio = ratios[c];
        } catch (e) {
            console.error(e);
        }
    },
};
